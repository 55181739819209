import React, {useState} from "react";
import NumberFormat from "react-number-format";

const Stars = ({ rating }) => (
	<span>
		{[...Array(5)].map(function(e, i) {
			return (
				<span key={i} className={i + 1 <= rating ? "icon icon-star-full" : "icon icon-star"} />
			)
		})}
	</span>
);


const Product = React.forwardRef(({ product, cdnPath, path, promoted, formValues, scrollTarget}, ref) => {
	const isPromoted = promoted && product.promoted ? true : false
	const wrapperClass = isPromoted ? "product-grid__product--link promoted" : "product-grid__product--link"
	const title = `${product.name} in ${product.location}`
	const hasPrice = product.price != null && product.price ? true : false
	const hasCapacity = product.capacity || product.minimum ? true : false
	const [ selectedTime, setSelectedTime ] = useState(formValues ? formValues.time : false)
	
	let defaultProductPath = path + "/" + product.slug
	if( formValues &&  formValues.pax != undefined && formValues.pax > 0 &&  formValues.date != undefined && formValues.date != null ) {
		defaultProductPath = defaultProductPath + "?pax=" + formValues.pax + "&date=" + formValues.date
	}

	const [productPath, setProductPath] = useState(defaultProductPath)

	const capacity = () => {
		if( product.capacity == product.minimum ) {
			return <p>{product.capacity}</p>
		} else if( product.minimum > 0 && product.capacity == 0) {
			return <p>{product.minimum} + </p>
		} else if( product.capacity > 0  ) {
			return  <p>{product.minimum}{" "}-{" "}{product.capacity}</p>
		} else {
			return null
		}
	}

	const price = () => {
		return(
			<>
			{ product.price_from && <p>From{" "}</p>}
			<NumberFormat
				value={product.price}
				displayType={"text"}
				thousandSeparator={true}
				prefix={"£"}
				decimalScale={2}
				fixedDecimalScale={true}
			/>{" "}
			<p>{product.fixed_price ? "Per group" : "per person"}</p>
		  </>
		)
	}


	const handleClick = (e, time) => {
		e.preventDefault()
		e.stopPropagation()
		if( time ) {
			window.location.href = defaultProductPath + (formValues ? '&' : '?') + "time=" + time
		} else {
			window.location.href = defaultProductPath;
		}
	}

	return (
		<div className="cell product-grid__product">
			{scrollTarget && <span ref={ref} />}
			<a className={wrapperClass} href={productPath} onClick={(e) => handleClick(e, false)}>

				<div className="product-grid__product--image-wrapper" name={product.name}>

					<div className="image-container">
						<div className={`product-grid__product--image lazyload${product.sold_out ? ' sold-out' : ''}`} data-bg={`${cdnPath}/${product.image}?w=400&h=300&fit=crop&auto=compress`}>
							<div className="product-grid__product--image-overlay"></div>
						</div>
					
						{product.badge && <div className={`badges badge-` + product.badge} ></div>}

					</div>

					<div className="product-grid__product--name">
						<div>
							{isPromoted && <div className="sponsored"><p>Sponsored</p></div>}

							<span className="product-grid__product--heading">
								<span>{title}</span>
							</span>

							{product.sold_out &&
								<p className="mg-v-sm">
									<span className="product-grid__product--banner sold-out">2025 DATES COMING SOON</span>
								</p>
							}

							<div className="align-content split">
								{product.reviews > 0 && (
								<p className="product-grid__product--reviews">
									<Stars rating={product.rating} />{" "}<span>({product.reviews})</span>
								</p>
								)}
							</div>

							<p className="product-grid__product--supplier">
								{!product.sold_out && product.supplier}
							</p>
						</div>

						<div className={hasCapacity ? "align-content vertical bottom horizontal split" : "align-content vertical bottom horizontal right"}>
							
							{hasCapacity && (
								<div className="align-content vertical center">
									<span className="icon icon-group mg-r-sm"></span>
									{capacity()}
								</div>
							)}
							
							{hasPrice && (			
								<span className="product-grid__product--price">{price()}</span>			
							)}

							
						</div>

						{product.times? (
							<div className="product-grid__product--times">
								<div className="list">
									{product.times.map((time, index) => (
										<span
											onClick={(e)=> handleClick(e, time)}
											key={index}
										>{time}</span>
									))}
								</div>
								{product.hidden_times > 0 && ( <div className="more">+{product.hidden_times} more times available</div> )}
							</div>
						): null}
					</div>

					<div className="product-grid__product--summary">
						<span>{product.summary}</span>
					</div>

					<div className="view-btn">
						<button className="button primary small" href={productPath}>View</button>
					</div>

				</div>
			</a>
		</div>
	)
})

export default Product